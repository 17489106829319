import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {env} from '../Models/env'
import { Subject } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class OffreService {
  private env = env.apiURL;
  public offres;updatedOffre = new Subject<any[]>(); public prixTot=0;
  quantite=0
  updatedQuantite= new Subject<number>();
  public cart:any[]=[];
  updatedCart=new Subject<any[]>()
  offre: any;
  constructor(private storage:Storage,private http: HttpClient, private cookie:CookieService) { }
  displayOffre(){
    this.http.get<Object[]>(`${this.env}get/offre`).subscribe(data=>{this.offres=data
    
      this.updatedOffre.next(this.offres.slice());
    })
   return this.http.get<Object>(`${this.env}get/offre`);
  }
  provideOffre(o){
    let plat;
    if(o!=null)
    this.offres.map(off=>{if (off .id===o.id) this.offre=off})
    else
    this.offre=null
  }

  resetCart(){this.updatedQuantite.next(0);
    this.cart=[];
    this.updatedCart.next(this.cart.slice())
  }
  addOffreToCart(offre){
    
      this.quantite=this.quantite+1
      this.updatedQuantite.next(this.quantite)
      
    offre.quantity=1;
      this.cart.push({...offre})
      this.calculPrix()
      this.updatedCart.next(this.cart.slice())
    
  }
  calculPrix(){
    this.prixTot=0
    this.cart.map((p:any)=>{
      //console.log(p)
      this.prixTot+=Number(p.prix)*p.quantity
   //console.log(this.prixTot);
    })



  }

  removeOffre(i){
    this.quantite=this.quantite-1
    this.updatedQuantite.next(this.quantite)
    if(this.cart[i].quantity-1==0){
    if(this.cart.length==1)
    this.cart=[]
    else
    this.cart.splice(i,1)}
    else
    this.cart[i].quantity-=1
    this.calculPrix()
    this.updatedCart.next(this.cart.slice())
  }

  addOffre(i){
    
    this.quantite=this.quantite+1
    this.updatedQuantite.next(this.quantite)
   // this.cart[i].quantity+=1
   //console.log(this.cart[i])
   this.cart.map((e:any,index)=>{if(index===i){
     
    e.quantity+=1
     return e;
   }})
 
 
    this.calculPrix()
     this.updatedCart.next(this.cart.slice())
   }


   reduirePrix(code,jwt){
    let prixtot=this.prixTot;
    //console.log(prixtot);
    return this.http.get(`https://api.naanpalace.fr/api/reduction/${code}/${prixtot}`, { headers: {Authorization: 'Bearer ' +jwt}})
    }

}
